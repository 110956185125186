<template>
  <div class="apply-for-mastercard-vue">
    <Header class="responsive-bar">
      <template v-slot:title>
        {{ $t("apply-for-mastercard") }}
      </template>
    </Header>
    <div>
      <div
        v-if="$store.getters.get_profile.state !== 'Full'"
        class="bg-white shadow rounded-md min-h-screen m-auto max-w-3xl sm:mt-5"
      >
        <div class="pb-5">
          <div class="flex justify-between">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ $t("apply-for-mastercard") }}
            </h3>
          </div>
          <p
            v-if="
              $store.getters.get_profile.state !== 'Review' &&
              $store.getters.get_profile.state !== 'Pending'
            "
            class="mt-1 max-w-2xl text-sm leading-5 text-gray-500"
          >
            {{ $t("all-fields-below-are-mandatory") }}
          </p>
        </div>
        <complete-id-identification
          v-if="$store.getters.get_profile.state !== 'Full' && this.whitelabel !== 'ICMMU'"
          title="before-you-are-able-to-apply-for-mastercard-you-must-complete-id-verification-process"
        ></complete-id-identification>
        <!--#BR#23 - Mbeng Atemson 23/10/2024 -KYC verification message  and whitelabel !== 'ICMMU' -->
        <complete-id-identification
          v-if="$store.getters.get_profile.state !== 'Full' && this.whitelabel === 'ICMMU'"
          title="KYC verification is pending, please contact Support team"
          
        ></complete-id-identification>
      </div>
      <div
        v-else
        class="bg-white shadow rounded-md min-h-screen m-auto max-w-3xl sm:mt-5"
      >
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(apply)">
            <div class="pb-5">
              <div class="flex justify-between">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  {{ $t("apply-for-mastercard") }}
                </h3>
              </div>
              <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                {{ $t("all-fields-below-are-mandatory") }}
              </p>
            </div>
            <div>
              <div>
                <div>
                  <!--    Full name  -->
                  <div class="py-4 border-t">
                    <ValidationProvider
                      v-slot="{ errors }"
                      class="sm:grid sm:grid-cols-4"
                      name="full_name"
                      rules="required"
                    >
                      <label
                        class="block text-sm font-normal leading-5 col-span-2"
                        for="full_name"
                      >
                        {{ $t("full-name-f11b368cddfe37c47af9b9d91c6ba4f0") }}
                      </label>
                      <div class="col-span-2">
                        <div class="mt-1 rounded-md shadow-sm">
                          <input
                            id="full_name"
                            v-model="form.full_name"
                            :placeholder="$t('full-name')"
                            class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            type="text"
                          />
                        </div>
                        <span class="text-sm text-red-600 h-4">{{
                          errors[0]
                        }}</span>
                      </div>
                    </ValidationProvider>
                  </div>

                  <!--    Trading account   -->

                  <div class="py-4 border-t">
                    <div class="sm:grid sm:grid-cols-4">
                      <div class="text-sm leading-5 col-span-2">
                        <label>
                          {{ $t("mt4-account-number") }}
                        </label>
                      </div>
                      <need-trading-account-alert
                        v-if="$store.getters.get_live_accounts.length === 0"
                        class="col-span-2"
                        title="You need to have a trading account in order to apply for mastercard."
                      >
                      </need-trading-account-alert>
                      <div v-else class="col-span-2">
                        <div
                          v-for="(account, index) in account_data"
                          :key="index"
                          :class="
                            index === 0 && account_data.length > 1
                              ? 'rounded-t-md border-t'
                              : '' ||
                                (index === account_data.length - 1 &&
                                  account_data.length > 1)
                              ? 'rounded-b-md border-b'
                              : '' || account_data.length === 1
                              ? 'rounded-md border-t'
                              : ''
                          "
                          class="border-l border-r border-b px-3 py-1 sm:grid sm:grid-cols-8 w-full h-18 cursor-pointer"
                          @click="setAccount(account)"
                        >
                          <div class="col-span-1">
                            <svg
                              v-if="form.trading_account === account.id"
                              fill="none"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="12"
                                cy="12"
                                fill="white"
                                r="6"
                                stroke="#00B8EE"
                                stroke-width="4"
                              />
                            </svg>
                            <svg
                              v-else
                              fill="none"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="12"
                                cy="12"
                                fill="white"
                                r="7.5"
                                stroke="#D3D7DD"
                              />
                            </svg>
                          </div>
                          <div v-if="account.currency" class="col-span-7">
                            <div class="text-sm">
                              {{ account.backend.type }} -
                              {{ account.external_id }}
                            </div>
                            <span class="text-xs font-light text-gray-400">
                              {{
                                new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: account.currency,
                                }).format(account.balance.WithdrawableAmount)
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--    Card currency    -->
                  <div class="py-4 border-t">
                    <ValidationProvider
                      v-slot="{ errors }"
                      class="sm:grid sm:grid-cols-4"
                      name="Card currency"
                      rules="required"
                    >
                      <div class="text-sm leading-5 col-span-2">
                        <label>
                          {{ $t("card-currency") }}
                        </label>
                      </div>
                      <div class="col-span-2">
                        <v-select
                          v-model="form.card_currency"
                          :clearable="false"
                          :dir="
                            checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'
                          "
                          :disabled="
                            $store.getters.get_live_accounts.length === 0
                          "
                          :options="card_currency.choices"
                          :placeholder="
                            $t(
                              'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                            )
                          "
                          :reduce="(item) => item.value"
                          class="style-chooser"
                          label="display_name"
                        ></v-select>
                        <span class="text-sm text-red-600 h-4">{{
                          errors[0]
                        }}</span>
                      </div>
                    </ValidationProvider>
                  </div>

                  <!--   House/Building number   -->

                  <div class="py-4 border-t">
                    <ValidationProvider
                      v-slot="{ errors }"
                      class="sm:grid sm:grid-cols-4"
                      name="addr_building"
                      rules="required"
                    >
                      <label
                        class="block text-sm font-normal leading-5 col-span-2"
                        for="full_name"
                      >
                        {{ $t("house-building-number") }}
                      </label>
                      <div class="col-span-2">
                        <div class="mt-1 rounded-md shadow-sm">
                          <input
                            id="addr_building"
                            v-model="form.addr_building"
                            :disabled="
                              $store.getters.get_live_accounts.length === 0
                            "
                            :placeholder="$t('house-building-number')"
                            class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            type="text"
                          />
                        </div>
                        <span class="text-sm text-red-600 h-4">{{
                          errors[0]
                        }}</span>
                      </div>
                    </ValidationProvider>
                  </div>

                  <!--   Street   -->

                  <div class="py-4 border-t">
                    <ValidationProvider
                      v-slot="{ errors }"
                      class="sm:grid sm:grid-cols-4"
                      name="addr_street"
                      rules="required"
                    >
                      <label
                        class="block text-sm font-normal leading-5 col-span-2"
                        for="full_name"
                      >
                        {{ $t("Street") }}
                      </label>
                      <div class="col-span-2">
                        <div class="mt-1 rounded-md shadow-sm">
                          <input
                            id="addr_street"
                            v-model="form.addr_street"
                            :disabled="
                              $store.getters.get_live_accounts.length === 0
                            "
                            :placeholder="$t('Street')"
                            class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            type="text"
                          />
                        </div>
                        <span class="text-sm text-red-600 h-4">{{
                          errors[0]
                        }}</span>
                        <span
                          v-if="
                            form.addr_street.length > 0 &&
                            (whitelabel === 'ICMCapital' ||
                              whitelabel === 'ICMMENA')
                          "
                          class="text-sm text-gray-400 font-light h-4"
                        >
                          {{
                            $t(
                              "please-note-in-order-to-open-the-account-your-address-in-your-application-form-must-exactly-match-th"
                            )
                          }}
                        </span>
                      </div>
                    </ValidationProvider>
                  </div>

                  <!--   City   -->

                  <div class="py-4 border-t">
                    <ValidationProvider
                      v-slot="{ errors }"
                      class="sm:grid sm:grid-cols-4"
                      name="city"
                      rules="required"
                    >
                      <label
                        class="block text-sm font-normal leading-5 col-span-2"
                        for="full_name"
                      >
                        {{ $t("City") }}
                      </label>
                      <div class="col-span-2">
                        <div class="mt-1 rounded-md shadow-sm">
                          <input
                            id="city"
                            v-model="form.addr_city"
                            :disabled="
                              $store.getters.get_live_accounts.length === 0
                            "
                            :placeholder="$t('City')"
                            class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            type="text"
                          />
                        </div>
                        <span class="text-sm text-red-600 h-4">{{
                          errors[0]
                        }}</span>
                      </div>
                    </ValidationProvider>
                  </div>

                  <!--   Postcode   -->

                  <div class="py-4 border-t">
                    <ValidationProvider
                      v-slot="{ errors }"
                      class="sm:grid sm:grid-cols-4"
                      name="post_code"
                      rules="required"
                    >
                      <label
                        class="block text-sm font-normal leading-5 col-span-2"
                        for="full_name"
                      >
                        {{ $t("Postcode") }}
                      </label>
                      <div class="col-span-2">
                        <div class="mt-1 rounded-md shadow-sm">
                          <input
                            id="post_code"
                            v-model="form.addr_zip"
                            :disabled="
                              $store.getters.get_live_accounts.length === 0
                            "
                            :placeholder="$t('Postcode')"
                            class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            type="text"
                          />
                        </div>
                        <span class="text-sm text-red-600 h-4">{{
                          errors[0]
                        }}</span>
                      </div>
                    </ValidationProvider>
                  </div>

                  <!--   Country   -->

                  <div class="py-4 border-t">
                    <ValidationProvider
                      v-slot="{ errors }"
                      class="sm:grid sm:grid-cols-4"
                      name="country"
                      rules="required"
                    >
                      <label
                        class="block text-sm font-normal leading-5 col-span-2"
                        for="country"
                      >
                        {{ $t("Country") }}
                      </label>
                      <div class="col-span-2">
                        <div class="mt-1 rounded-md shadow-sm">
                          <input
                            id="country"
                            v-model="form.addr_country"
                            :disabled="
                              $store.getters.get_live_accounts.length === 0
                            "
                            :placeholder="$t('Country')"
                            class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            type="text"
                          />
                        </div>
                        <span class="text-sm text-red-600 h-4">{{
                          errors[0]
                        }}</span>
                      </div>
                    </ValidationProvider>
                  </div>

                  <!--   Terms and conditions   -->

                  <div
                    v-if="
                      whitelabel === 'ICMCapital' || whitelabel === 'ICMMENA'
                    "
                    class="py-4 border-t"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="tos_accepted"
                      rules="required"
                    >
                      <div class="relative flex items-start">
                        <div class="flex items-center h-5">
                          <input
                            id="tos_accepted"
                            v-model="form.tos_accepted"
                            :disabled="
                              $store.getters.get_live_accounts.length === 0
                            "
                            :required="true"
                            class="form-checkbox h-4 w-4 transition duration-150 ease-in-out"
                            type="checkbox"
                          />
                        </div>
                        <div class="ms-3 text-sm leading-5 html-a">
                          <label
                            class="font-normal"
                            for="tos_accepted"
                            @input="$event, form.tos_accepted"
                          >
                            <p v-html="agree" />
                          </label>
                        </div>
                      </div>
                      <span class="text-sm text-red-600 h-4">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="w-full mt-5">
                <span class="inline-flex w-full rounded-md shadow-sm">
                  <Button
                    :disabled="
                      isDisabled ||
                      $store.getters.get_live_accounts.length === 0
                    "
                    class="relative w-full block bg-main-button-color items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none focus:shadow-outline-indigo"
                    type="submit"
                  >
                    {{ $t("Submit") }}
                  </Button>
                </span>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Header from "@/components/Header";
import CompleteIdIdentification from "@/components/CompleteIdIdentification";
import NeedTradingAccountAlert from "@/components/NeedTradingAccountAlert";

export default {
  name: "ApplyForMastercard",
  components: {
    Header,
    CompleteIdIdentification,
    NeedTradingAccountAlert,
  },
  data() {
    return {
      form: {
        full_name: "",
        trading_account: "",
        card_currency: "",
        addr_building: "",
        addr_street: "",
        addr_city: "",
        addr_zip: "",
        addr_country: "",
      },
      card_currency: [],
      whitelabel: process.env.VUE_APP_WHITELABEL,
      isDisabled: false,
      error: {
        status: false,
        message: "",
      },
      languageDirection: "",
    };
  },
  computed: {
    ...mapGetters([
      "get_card_requests",
      "get_profile",
      "get_uploaded_documents",
    ]),

    agree() {
      return this.$sanitize(
        this.$t(
          "i-full-name-have-read-and-agree-to-the-prepaid-card-terms-and-conditions-da73dc1fd2720ba575a653d86cc"
        ).replace("$full_name", this.form.full_name)
      );
    },

    account_data() {
      return this.$store.getters.get_account_data.filter(
        (account) =>
          account.backend.mode === "Live" && account.backend.type === "MT4"
      );
    },

    // on select forms, we need to set dir = 'rtl' instead of default dir='auto'
    // so non translated text would show the corect way on arab and persian pages
    checkLanguageDirection() {
      this.languageDirection = this.$store.getters.get_lang;
      if (this.lang === "ar" || this.lang === "fa") {
        return document.getElementById("body").dir;
      } else {
        return document.getElementById("body").dir;
      }
    },
  },
  mounted() {
    this.$store.dispatch("profile").then((resp) => {
      this.form.full_name = resp.data.first_name + " " + resp.data.last_name;
    });
    this.$store.dispatch("card_requests").then((resp) => {
      this.card_currency.choices = resp.data.actions.POST.fields.find(
        (item) => item.key === "card_currency"
      ).choices;
    });
    this.$store.dispatch("account_data").then((res) => {
      if (res.data.length > 0) {
        this.form.trading_account = res.data[0].id;
      }
    });
  },
  methods: {
    setAccount(account) {
      this.form.trading_account = account.id;
    },
    apply() {
      this.isDisabled = true;
      this.form.tos_accepted = false;

      this.$store
        .dispatch("apply_for_mastercard", this.form)
        .then(() => {
          this.isDisabled = false;
          this.$notify({
            group: "foo",
            text: `${this.$t("your-application-has-been-sent")}`,
          });
        })
        .catch((err) => {
          this.isDisabled = false;
          this.$notify({
            group: "foo",
            text: `${err}`,
            type: "warn",
          });
        });
    },
  },
};
</script>
